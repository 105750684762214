<template>
<div>  
<nav-bar></nav-bar>
<div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <div class="main-panel">
    <div class="content-wrapper" id="main-content">
      <div>
        <div class="row justify-content-center">
          <button type="button" @click="printPrescription()" class="btn btn-gradient-primary me-2 col-md-2">Print</button>
        </div>
        <br/>
      </div>
      <div id="print-area">
        <page size="A4" :style="{'--bkgImage': 'url(' + prescription_background + ')'}">
          <div class="detila-start">
            <table>
              <tr>
                <td style="width: 40%; max-width: 60vw;"><b>Name: </b> <span>{{patientDetails.patient_name}}</span></td>
                <td style="width: 30%; max-width: 60vw;"><b>Mobile: </b> <span>{{patientDetails.patient_mobile_number}}</span></td>
                <td style="width: 10%; max-width: 20vw;"><b>Age: </b> <span>{{patientDetails.patient_age}}</span></td>
              </tr>
              <tr>
                <td style="width: 40%; max-width: 60vw;"><b>Patient Number:</b> <span>{{patientDetails.patient_number}}</span></td>
                <td style="width: 30%; max-width: 60vw;"><b>Village:</b> <span>{{patientDetails.patient_city}}</span></td>
              </tr>
            </table>
            <hr>

            <span v-if="reportDatas.next_visit_number" class="text-primary"><b><u>Next visit after {{reportDatas.next_visit_number}} {{reportDatas.next_visit_type}}</u></b></span>
            <br v-if="reportDatas.next_visit_number" />
            <br v-if="reportDatas.next_visit_number" />
            <div class="row">
                <div class="col-6 col-lg-6">
                    <div class="row">
                      <div v-if="reportDatas">
                        <h3 class="title">Medicines</h3>
                        <div v-for="(medicn, i) in reportDatas.prescription_medicine" :key="i">
                          <p class="mdi-name">{{i+1}}. {{medicn.medicine_name}} &nbsp;&nbsp;&nbsp;&nbsp;<span>{{medicn.medicine_morning}}-{{medicn.medicine_noon}}-{{medicn.medicine_evening}} - <span class="qntity">{{medicn.medicine_quantity}}</span></span></p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-6 col-lg-6">
                    <div class="row" v-if="reportDatas.prescription_report.length > 0">
                      <div v-if="reportDatas">
                        <h3 class="title">Reports</h3>
                        <div v-for="(repo, i) in reportDatas.prescription_report" :key="i">{{repo.report_name}}</div>
                      </div>
                    </div>
                    <div class="row mt-5" v-if="reportDatas.prescription_notes != '-'">
                      <div>
                        <h3 class="title">Notes</h3>{{reportDatas.prescription_notes}}
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </page>
      </div>
    </div>
    <Footer />
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import string from "../constant/strings.js"

export default {
  name:'prescriptions-report',
    title: string.PAGE_TITLE_PRESCRIPTION,
  components: {
    NavBar,
    SideBar,
    Footer
  },
  data() {
    return {
      prescription_background: '',
      reportDatas:{},
      patientDetails:{}
    }
  },
  mounted(){
    this.getSettings()
  },
  methods: {
    ...mapActions("hospital",["getPrescriptionData", "getSettingsData"]),
    getAddPrescriptionFun(id) {      
      var bodyFormData = new FormData();
      bodyFormData.append('opd_id', id);
      this.getPrescriptionData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          console.log("response.data",response.data);
          this.reportDatas = response.data;
          this.patientDetails = response.patient_details;          
        }
      });
    },
    getSettings(){
      this.getSettingsData().then((response) => {
        if (response.response_code == 200) {
            this.prescription_background = response.data.prescription_background;
            this.getAddPrescriptionFun(this.$route.params.id);
        }else{
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },
    printPrescription() {
        window.print();
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* Set A4 size for print */
@media print {
  #print-area {
    width: 210mm;
    height: 297mm;
    background-image: var(--bkgImage);
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    overflow: hidden;
    page-break-inside: avoid;
  }
}

/* General responsive adjustments */
#main-content {
  padding: 35px 0px; /* Main padding adjustments */
}

#print-area {
  background-image: var(--bkgImage);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: auto; /* Allow horizontal scroll if needed */
}

.detila-start {
  font-size: 1rem;
  padding: 146px 21px; /* Padding adjustments for print */
}

/* Adjust layout on smaller screens */
@media (max-width: 768px) {
  .container-fluid.page-body-wrapper {
    padding: 0;
  }
  .main-panel {
    padding: 60px 0px 0px 10px;
  }

  .content-wrapper {
    padding: 0;
    overflow-x: auto; /* Horizontal scroll for mobile */
  }
  #print-area {
    background-image: var(--bkgImage);
    padding: 0px;
    overflow-x: auto; /* Horizontal scroll for content */
  }

  /* Responsive font size and spacing */
  .title, .med-title {
    font-size: 1.2rem;
  }
  .mdi-name, .qntity {
    font-size: 0.9rem;
  }
  .detila-start, .row {
    margin: 0 auto;
    width: 100%;
  }
  .col-sm-4, .col-md-2 {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>